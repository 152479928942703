import { mapActions, mapGetters } from 'vuex';
import { NA_POSTCODE } from '@/core/components/property/constants';
import { PROPERTY_STORE } from '@/store/modules/property-details';
import { USERS_STORE } from '@/store/modules/users';
export default {
    data() {
        return {
            property: null,
            NA_POSTCODE
        };
    },
    async created() {
        await this.initialize();
    },
    computed: {
        routeName() {
            return this.$route.name;
        },
        ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized']),
    },
    methods: {
        ...mapActions(PROPERTY_STORE, ['getProperty']),
        routeToPropertyHome() {
            this.$router.push({ name: 'property-details/home' });
        },
        routeToPropertyDocuments() {
            this.$router.push({ name: 'property-details/documents' });
        },
        routeToPropertyMembers() {
            this.$router.push({ name: 'property-details/members' });
        },
        routeToPropertyWallet() {
            this.$router.push({ name: 'property-details/wallet' });
        },
        routeToPropertyKYP() {
            this.$router.push({ name: 'property-details/kyp' });
        },
        routeToPropertyProjects() {
            this.$router.push({ name: 'property-details/projects' });
        },
        async initialize() {
            const { id } = this.$route.params;
            await this.getProperty(id).then((raw) => {
                const result = { postcode: raw.postcode };
                this.property = result;
            });
        },
    },
};
